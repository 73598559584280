<style lang="scss">
@import "~@/assets/css/var";

.txt-green {
    color: $green;
}

.txt-blue {
    color: $main;
}

.txt-red {
    color: $red;
}

.clickable {
    cursor: pointer;
    color: #4f6fd7;

    &:hover {
        opacity: .7;
    }
}

.html-view * {
    text-wrap: unset !important;
}

.taoshu {
    padding-top: 50px;
    flex-direction: column;

    &-header {
        position: fixed;
        z-index: 102;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        height: 50px;
        box-shadow: 0 5px 18px 0 rgba(122, 145, 219, 0.3);
        padding: 0 20px;
        background-color: #fff;

        /*overflow: hidden;*/
        .logo {
            width: 100px;
        }

        .menu {
            position: relative;
            margin-left: 24px;
            width: 90px;
            font-size: 0;

            .hot {
                position: absolute;
                width: 30px;
                top: 12px;
                right: -12px;
            }
        }

        &__menu {
            display: flex;
            flex: 1;
            overflow: hidden;
            flex-wrap: nowrap;

            .el-menu {
                display: flex;
                margin-left: 24px;
                border: 0;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .el-menu-item {
                height: 50px;
                line-height: 50px;
            }

            .el-submenu {
                padding: 0 17px !important;

                &:not(.is-active) {
                    border: 0 !important;
                }

                .el-submenu__title {
                    padding: 0;
                    border: 0;
                    height: 50px;
                    line-height: 50px;
                }

                &.is-opened,
                &:hover {
                    color: #fff !important;
                    background: url('~@/assets/img/nav-active.png') no-repeat center / 100% 100% !important;

                    .el-submenu__title {
                        color: #fff;
                        background: transparent;
                    }
                }
            }

            .el-submenu__icon-arrow {
                display: none;
            }

            .recommend {
                position: absolute;
                z-index: 1;
                top: 12px;
                right: -15px;
                font-size: 10px;
                font-style: normal;
                color: #fff;
                background: $red;
                width: 14px;
                line-height: 14px;
                text-align: center;
                border-radius: 4px;
            }

            .hot {
                position: absolute;
                top: 12px;
                right: -6px;
                width: 20px;
            }
        }

        &__user {
            .el-link {
                font-weight: normal;
            }
        }

        &__avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            position: relative;

            img {
                width: 35px;
                height: 35px;
            }

            .isRead {
                color: #ff0000;
                position: absolute;
                right: -10px;
                top: -10px;
            }
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: 1440px;
    }

    // .feedBack{
    //     height: 108px;
    //     width: 108px;
    //     position: fixed;
    //     right: 10px;
    //     top: 50%;
    //     z-index: 10;
    // }
    .custom-btns {
        position: fixed;
        right: -75px;
        top: 50%;
        z-index: 100;
        transition: right .3s;

        &.active {
            right: 10px;
        }

        .close {
            padding-right: 10px;
            color: $red;
            text-align: right;
            font-size: 16px;
        }

        .img-name {
            margin: 10px;
            display: flex;
            align-items: center;
            padding: 8px;
            background-color: $main;
            color: #fff;
            border-radius: 5px;
            box-shadow: 1px 1px 3px #d0d0d0;
            font-size: 14px;
            cursor: pointer;
            position: relative;

            .img {
                width: 22px;
                height: 22px;
            }

            .name {
                padding-left: 5px;
            }

            .icon {
                font-size: 22px;
            }

            &:hover {
                background-color: #ffa921;
            }
        }
    }

    .sidebar {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 101;
        transition: right .3s;
        padding-top: 50px;
        box-shadow: 2px 0px 4px 6px rgba(0, 0, 0, 0.1);

        &_right {
            width: 40px;
            height: 100vh;
            text-align: center;

            &_width {
                min-width: 110px;
            }

            .el-badge {
                display: flex;
                width: auto;
                height: auto;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
            }

            .is-fixed {
                top: -2px;
                right: 20px;
            }

            .el-badge__content {
                font-size: 8px;
                height: 12px;
                line-height: 12px;
                padding: 0 3px;
            }

            .el-menu-item {
                padding: unset !important;
                height: 60px;
                line-height: 60px;

                &:hover {
                    background: #ffd04b !important;
                }

                .img {
                    width: 25px;
                    height: 25px;
                }

                .el-tooltip {
                    padding: unset !important;
                }

                .el-tooltip:hover {
                    background: #ffd04b;
                }
            }
        }

        .expand {
            position: absolute;
            left: -26px;
            top: 50%;
            z-index: 101;
            width: 16px;
            height: 45px;
            line-height: 16px;
            color: #fff;
            font-size: 12px;
            text-align: center;
            margin-top: -43px;
            padding: 10px 5px 30px 5px;
            border-radius: 4px 0 0 4px;
            background-color: rgba(76, 133, 237, 0.8);
            cursor: pointer;
        }

        .menu_item {
            display: flex;
            width: auto;
            height: auto;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            &_text {
                white-space: break-spaces;
                width: 20px;
                height: 30px;
                font-size: 10px;
                line-height: 1.2;
            }
        }
    }

}

.is-dark {
    // background: #F7F7F7 !important;
    background: radial-gradient(49% 49% at 105% -11%, #cdd8fb 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(57% 57% at -13% -22%, #c4d2f3 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #E3EEFC 0%, #FEFFFF 33%, #FFFFFF 83%) !important;
    color: #303133 !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    text-align: center;

    .name {
        font-size: 14px;
        font-weight: bold;
    }

    .big {
        width: 120px;
        height: 120px;
    }

    .list {
        display: flex;
        align-items: center;
        font-size: 12px;

        .number {
            color: #5171DA;
        }
    }

    .comText {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;

        .comText_height {
            // width: 200px;
            max-height: 200px;
            overflow: auto;
        }

        .comText_com {
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            padding: 4px 20px;
        }

        .comText_btn {
            margin-top: 10px;
            background: #5171DA;
            color: #fff;
            width: 40%;
            text-align: center;
            border-radius: 2px;
            cursor: pointer;
            font-size: 12px;
        }

    }

    .popper__arrow {
        background: transparent !important;
        border-left-color: #F7F7F7 !important;
    }

    .popper__arrow::after {
        border-left-color: #F7F7F7 !important;
    }
}



.cs-btn {
    position: fixed;
    top: 300px;
    left: 100px;
    z-index: 888;

    .img-cs {
        &__panel {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            .tip-num {
                position: absolute;
                top: 8px;
                right: -6px;
                background: $red;
                color: #fff;
                line-height: 20px;
                border-radius: 10px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: translate(-50%, -30%);
                min-width: 20px;
                font-size: 10px;
            }

            img {
                width: 80px;
                height: 80px;
            }
        }
    }
}

.el-select {
    .el-select__caret:first-child::before {
        content: "\e6e1";
    }

    .is-focus {
        .el-select__caret:first-child {
            transform: rotateZ(0deg);
        }
    }
}

.el-menu--popup {
    padding: 0;
    min-width: unset;
}

.el-menu--horizontal .el-menu .el-menu-item {
    padding: 0 34px;
    font-size: 12px;
    line-height: 36px;
    color: #333;
    text-align: center;
    border-bottom: 1px solid #e1e1e1;

    &:hover {
        background: #D4DEFF;
    }
}

.red_i::after {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #ff0000;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>

<style lang="scss">
@import "~@/assets/css/var";

.hot-recommend {
    position: relative;
    width: 180px;
    line-height: 52px;
    margin-bottom: 20px;
    text-align: center;
    background: linear-gradient(180deg, #EDEDED, #FFFFFF);
    box-shadow: 0 3px 5px 0 rgba(149, 164, 213, 0.3);
    font-size: 14px;
    font-weight: 400;
    color: #000;
    cursor: pointer;
    transition: all .3s;

    &:nth-child(1) {
        i {
            background: #4260d1;
            color: #fff;
        }
    }

    &:nth-child(2) {
        i {
            background: #637cd9;
            color: #fff;
        }
    }

    &:nth-child(3) {
        i {
            background: #8699e0;
            color: #fff;
        }
    }

    i {
        position: absolute;
        transition: all .3s;
        top: -8px;
        width: 32px;
        line-height: 32px;
        text-align: center;
        left: 0;
        color: #fff;
        background: #D9DCE5;
        border-radius: 6px 6px 6px 0;
        font-style: normal;
        font-size: 18px;
        font-weight: bold;
    }

    .hot {
        position: absolute;
        top: 0;
        right: -18px;
        width: 30px;
    }

    &:hover {
        background: $main;
        color: #fff;

        i {
            color: $main;
            background: #fff;
        }
    }
}
</style>

<style lang="scss">
@import "~@/assets/css/var";

.common-table {
    display: flex;
    flex: 1;
    flex-direction: column;
    background: #F3F5F8;

    .p_blue {
        color: #5171DA;
        cursor: pointer;
    }

    .heover_btn {
        position: absolute;
        right: 10%;
        top: 50px;
        width: 60px;
        height: 20px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: height 0.4s ease-in-out;

        .border {
            width: 3px;
            height: 0;
            transition: height 0.5s ease-in-out;
            background: linear-gradient(to bottom, #b593f7, #dabdf5);
        }

        .img {
            margin-top: -22px;
            width: 60px;
            height: 80px;
            transition: margin-top 0.4s ease-in-out;
        }
    }

    .heover_btn:hover {
        height: 130px;

        .border {
            height: 50px;
        }

        .img {
            margin-top: 0;
        }

    }

    &__title {
        position: relative;
        display: flex;
        align-items: center;
        height: 50px;
        z-index: 101;
        line-height: 40px;
        background: #F8F8F8;
        box-shadow: 0 8px 8px 0 rgba(169, 175, 197, 0.35);
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        padding: 0 36px;
        white-space: nowrap;

        &:before {
            position: absolute;
            content: ' ';
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            width: 4px;
            height: 20px;
            background: $main;
        }

        &>.table-operate {
            position: absolute !important;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        &-list {
            overflow-x: auto;
            flex: 1;
            margin-right: 10px;
            white-space: nowrap;
        }

        &-item {
            padding: 0 15px;
            line-height: 26px;
            font-size: 16px;
            font-weight: 400;
            color: #4D4D4D;
            border: 1px solid #aaa;
            margin-right: 10px;
            border-radius: 4px;
            cursor: pointer;

            &.active,
            &:hover {
                color: #4C6BD4;
                border-color: #4C6BD4;
            }
        }
    }

    .ts-table {
        background: transparent;
        margin: 10px;
        padding-right: 0;

        .rank {
            width: 0;
            transition: width 0.8s ease-in-out;

            .rank-head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                white-space: nowrap;
            }

            .rank-list {
                white-space: nowrap;
            }
        }

        .hover_rank {
            width: 25%;
        }
    }

    .ts-search {
        float: right;
    }
}
</style>

<!--搜索结果-->
<style lang="scss">
@import "~@/assets/css/var";

.search-result {
    &-body {
        margin: 10px 20px 0;
    }

    .el-tabs {
        box-shadow: unset;
        background: unset;
        border: unset;
        display: flex;
        flex-direction: column;

        &__header {
            border: 0;

            .el-tabs__item {
                line-height: 46px;
                height: 46px;
                background: #DFE3F2;
                border-radius: 6px 6px 0 0;
                margin-right: 4px;
                font-size: 14px;
                font-weight: 400;
                color: #51566A;
                border: 0 !important;
                padding: 0 10px !important;
            }
        }

        &__nav-wrap {
            padding: 0;
        }

        &__content {
            display: none;
        }
    }

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 0 20px 12px;
        background: #fff;
    }

    &__panel {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0;

        &.show {
            opacity: 1;
            z-index: 2;
        }

        &>.flex-item {
            width: 100%;
        }
    }
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active span {
    border-bottom: 2px solid $main;
    padding-bottom: 10px;
}

.table-operate__right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
}

.with-sale {
    position: relative;

    &:not(.full) {
        margin-right: 220px;
        width: calc(100% - 220px) !important;
    }

    &-item {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0;

        &.active {
            opacity: 1;
            z-index: 1;
        }
    }

    .my-table {
        display: flex;
        flex-direction: column;
    }

    .table-operate {
        // position: relative;
        margin-right: 20px !important;

        &.center {
            margin-top: 20px;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
        }

        .bold {
            font-weight: bold;
        }
    }

    &-relation {
        position: absolute;
        top: 20px;
        right: -180px;
        width: 180px;

        &__title {
            font-size: 16px;
            line-height: 16px;
            font-weight: 500;
            color: #333333;
            margin: 4px 0 26px 0;
            padding-left: 10px;
            border-left: 4px solid $main;
        }

        &__btn {
            margin-bottom: 10px;
            background: #E9E9E9;
            border-radius: 2px;
            color: #333;
            line-height: 23px;
            font-size: 15px;
            padding: 14px 28px;
            text-align: center;
            cursor: pointer;

            &:hover {
                background: #ccc;
            }

            &.active {
                cursor: unset;
                color: #fff;
                background: $main;
            }
        }
    }

    .canvas-panel {
        position: absolute;
        z-index: 3;
        right: 0;
        top: 66px;
        width: 1100px;
        /*height: 631px;*/
        bottom: 0;
        background: #FFFFFF;
        box-shadow: -7px 7px 40px 0px rgba(100, 100, 100, 0.3);

        .close {
            position: absolute;
            z-index: 1;
            top: 26px;
            left: -5px;
            width: 96px;
            line-height: 48px;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
            padding-left: 9px;
            box-sizing: border-box;
            cursor: pointer;
            background: url(~@/assets/img/dialog-close.png) no-repeat center center / 100% auto;

            &:hover {
                color: #eee;
            }
        }

        .right-place {
            position: absolute;
            z-index: 6;
            top: 38px;
            right: 15px;
        }

        .auth-error {
            margin-top: 220px;
        }

        &__title {
            top: 43px;
            text-align: center;
            font-size: 14px;

            .bold {
                font-weight: bold;
            }
        }

        .rate-year {
            position: absolute;
            top: 0;
            left: 70%;
            right: 0;
            bottom: 0;
            padding: 0;

            .el-table {
                &__header {
                    th {
                        color: #000;
                        background: #F5F5F5 !important;
                    }
                }
            }
        }
    }
}

.chart-page {
    width: 100%;
    height: 100%;

    &--container {
        position: relative;
        display: flex;

        .canvas-data-panel {
            position: absolute;
            display: flex;
            flex-direction: column;
            right: 20px;
            top: 20px;
            min-width: 230px;
            bottom: 20px;
        }
    }
}

.canvas-point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;

}

.corp-sale {
    display: flex;
    flex-direction: column;

    &-title {
        position: relative;
        line-height: 67px;
        border-bottom: 1px solid #E4E4E4;
        margin: 0 20px;
        font-size: 14px;
        color: #333;

        i {
            font-size: 16px;
            margin-right: 7px;
        }

        span {
            cursor: pointer;

            &:hover {
                color: $main
            }
        }
    }

    &-select {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        /*input {*/
        /*box-shadow: 0 0 5px 0 rgba(81, 113, 218, 0.25) inset, 0 0 5px 0 rgba(81, 113, 218, 0.25);*/
        /*}*/
    }

    &-body {
        display: flex;
        flex: 1;
        padding: 30px;

        &.relative {
            position: relative;
        }

        .auth-error {
            align-self: center;
            justify-items: center;
            margin: 0 auto;
        }

        &__title {
            font-size: 16px;
            line-height: 1;
            font-weight: 500;
            color: #333333;
            border-left: 4px solid $main;
            padding-left: 10px;
        }

        &__table {
            padding: 33px 0 0 0 !important;
        }
    }
}

.year-select {
    margin: 0 !important;

    .popper__arrow {
        display: none;
    }

    ul {
        padding: 0;
    }

    li {
        border-bottom: 1px solid #E4E4E4;
        line-height: 37px;
        font-size: 12px;
        padding: 0;
        color: #666;
        text-align: center;

        &.selected {
            color: #fff;
            background: $main;
        }
    }
}
</style>

<!--药品穿透-->
<style lang="scss">
@import "~@/assets/css/var";

.through-page {
    flex-direction: column;
    background: #F3F5F8;

    .canvas-data-panel {
        top: 30px;
        bottom: 30px;
        right: 20px;
        display: flex;
        flex-direction: column;
    }

    &-panel {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1;
        margin: 10px;
        padding: 20px;
        background: #fff;
    }

    .auth-error {
        margin-top: 140px;
    }

    &-tips {
        line-height: 1.6;
        padding: 10px;

        .red {
            color: $red;
        }

        .blue {
            color: $main;
        }

        &__small {
            line-height: 1.4;
            padding: 5px;
            font-size: 11px;
        }
    }

    &-canvas {
        flex: 1;
        box-sizing: border-box;
        font-size: 0;
    }

    &-assist {
        position: absolute;
        z-index: 3;
        background: #fff;
        top: 20px;
        left: 20px;
        padding: 15px 15px 0;
        border-radius: 9px;
        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.29);

        .el-select {
            width: 278px;

            input {
                box-shadow: 0 0 5px 0 rgba(81, 113, 218, 0.25) inset, 0 0 5px 0 rgba(81, 113, 218, 0.25);
            }
        }

        &__center {
            margin-top: 15px;
            margin-bottom: 5px;

            .el-tabs__header {
                margin: 0;
            }

            .el-tabs__nav {
                margin: 0 10px;
            }
        }

        &__bottom {
            text-align: center;

            i {
                cursor: pointer;
                font-size: 25px;
                color: $main;

                &.active {
                    transform: rotate(180deg);
                }
            }
        }

        &__desc {
            display: none;
            padding-top: 20px;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 1;
        }
    }

    &-textBottom {
        max-height: 65%;
        overflow: auto;
        position: absolute;
        z-index: 3;
        background: #fff;
        top: 210px;
        left: 20px;
        padding: 15px 15px 0;
        border-radius: 9px;
        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.29);

        .title {
            font-weight: bold;
            font-size: 14px;
            color: #000;
            display: flex;
            align-items: center;
        }

        &_box {
            width: 278px;
            margin-top: 10px;

            .box_flex {
                // display: flex;
                font-size: 12px;

                &_title {
                    font-weight: bold;
                    color: #45d75f;
                }
            }
        }
    }

    &-content {
        position: relative;
        flex: 1;

        &__full {
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;

            &.show {
                opacity: 1;
                z-index: 2;
            }
        }
    }
}
</style>

<style lang="scss">
.full-carousel {
    .el-carousel__item {
        width: calc(100% - 20px);
        display: flex;
        flex-direction: column;
    }

    .el-carousel__button {
        background: #666;
    }

    .el-carousel__indicators--vertical {
        right: 5px;
    }
}
</style>

<style lang="scss">
@import "~@/assets/css/var";

.scroll-div {
    width: 100%;
    // padding-top: 20px;
    flex-direction: column;
    overflow: auto;

    &.flex {
        display: flex;

        .flex-center {
            align-items: center;
            justify-content: center;
            display: flex;
            flex: 1;
        }
    }
}

.total-title {
    position: relative;
    font-size: 15px;
    line-height: 35px;
    font-weight: bold;
    padding: 0 20px 0 30px;

    &:before {
        position: absolute;
        content: ' ';
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        width: 3px;
        height: 12px;
        background: #5171DA;
    }

    &__right {
        font-weight: normal;
        float: right;
    }

    &__line {
        background: #F3F5F8;
        height: 5px;
        margin-bottom: 20px;
    }
}

.canvas {
    margin: 20px auto 0;
    width: 90%;
    height: 600px;

    &.small {
        width: 1000px;
        height: 480px;
    }
}

.comment-star {
    margin: 0 -6px;
    font-size: 10px;
    display: flex;
    align-items: center;

    &__item {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;

        i {
            cursor: pointer;
            color: $main;
            margin-right: 3px;

            &:hover {
                opacity: .7;
            }
        }

        .el-icon-star-on {
            font-size: 15px;
            margin-left: -2px;
        }
    }
}

.show-cart {
    .el-select__caret:first-child::before {
        content: "\e6e1";
    }

    .is-focus {
        .el-select__caret:first-child {
            transform: rotateZ(0deg);
        }
    }
}

.click-a {
    cursor: pointer;
    color: $main;

    &:hover {
        opacity: .7;
    }
}
</style>

<template>
    <el-container class="taoshu" v-if="loaded">
        <div class="taoshu-header">
            <img class="logo" src="@/assets/img/logo1.png" alt="logo" @click="$router.push('/')">
            <p class="menu" v-if="$route.meta.nav">
                <img src="@/assets/img/menu.png" alt="menu">
                <img class="hot" src="@/assets/img/hot.png" alt="hot">
            </p>

            <div class="taoshu-header__menu">
                <el-menu :default-active="$route.path" mode="horizontal" router unique-opened>
                    <el-menu-item v-if="!$route.meta.nav" class="el-submenu" index="/nav">
                        淘数导航
                        <img class="hot" src="@/assets/img/hot.png" alt="hot">
                    </el-menu-item>
                    <el-submenu v-for="item in menu" :key="item.id" :index="item.id">
                        <template #title>
                            {{ item.menuName }}
                            <i class="recommend" v-if="item.id === 'DM13'">荐</i>
                        </template>
                        <el-menu-item v-for="child in item.children" :key="`${item.id}-${child.id}`"
                            v-show="child.menuCode !== 'mainf_drug_winning_informations'"
                            :index="getPage(child, item)">{{
        child.menuName }}</el-menu-item>
                    </el-submenu>
                    <!-- :disabled="child.id === 'DM15_13'" -->
                    <el-submenu index="report">
                        <template #title>
                            报告大厅
                            <i class="recommend">荐</i>
                        </template>
                        <el-menu-item class="el-submenu" index="/report">报告管理</el-menu-item>
                        <el-menu-item class="el-submenu" index="/report/general">自助报告</el-menu-item>
                    </el-submenu>
                    <!-- <el-menu-item class="el-submenu" index="/note">我的笔记</el-menu-item> -->
                    <!-- 销售数据 -->
                    <el-submenu index="sale" v-if="$help.token.get()">
                        <template #title>
                            我的任务
                        </template>
                        <!-- <el-menu-item class="el-submenu" index="/note">我的笔记</el-menu-item>
                        <el-menu-item class="el-submenu" index="/star">关注评论</el-menu-item> -->

                        <el-menu-item class="el-submenu" index="/make?type=0">我的说明书</el-menu-item>
                        <el-menu-item class="el-submenu" index="/make?type=1">我的产品文献</el-menu-item>
                        <el-menu-item class="el-submenu" index="/make?type=2">我的临床指南</el-menu-item>
                        <el-menu-item class="el-submenu" index="/make?type=3">我的临床路径</el-menu-item>
                        <el-menu-item class="el-submenu" index="/make?type=4">我的产品新闻</el-menu-item>
                        <el-menu-item class="el-submenu" index="/make?type=5">我的企业新闻</el-menu-item>
                        <!-- <el-menu-item class="el-submenu" index="/make?type=6">我的产品分析</el-menu-item>
                        <el-menu-item class="el-submenu" index="/make?type=7">我的竞品分析</el-menu-item> -->

                        <el-menu-item class="el-submenu" index="/sale/nationwide">全国销售数据库</el-menu-item>
                        <el-menu-item class="el-submenu" index="/sale/terminal">终端销售数据库</el-menu-item>
                        <el-menu-item class="el-submenu" index="/sale/province">省份销售数据库</el-menu-item>
                    </el-submenu>
                    <el-menu-item class="el-submenu" index="/help">帮助中心</el-menu-item>
                </el-menu>
            </div>
            <div class="taoshu-header__avatar" v-if="$help.token.get()">
                <img v-if="$store.state.user.picUrl" :src="$store.state.user.picUrl" alt="头像"
                    @click="$refs.avatar.init($store.state.user.picUrl)">
                <img v-else src="@/assets/img/avatar-user.jpg" alt="头像"
                    @click="$refs.avatar.init($store.state.user.picUrl)">
                <i v-if="$store.state.isRead" class="iconfont icon-APP-yiduweidu- isRead" style="color: #ff0000;"></i>
            </div>
            <div class="taoshu-header__user" v-if="!$help.token.get()">
                <el-link type="primary" @click="$router.push('/register')" :underline="false">免费注册</el-link>
                <el-link :underline="false" style="margin-left: 40px;" @click="$help.goLogin()">登录</el-link>
            </div>
            <div class="taoshu-header__user" v-else>

                <el-dropdown @command="handleCommand" size="normal">
                    <span class="el-dropdown-link">
                        {{ $store.state.user.userName }}<i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="note"><i class="el-icon-notebook-1"></i> 我的笔记</el-dropdown-item>
                        <el-dropdown-item command="star"><i class="el-icon-folder-add"></i> 关注评论</el-dropdown-item>

                        <el-dropdown-item command="message" :class="$store.state.isRead ? 'red_i' : ''"
                            style="position: relative;"><i class="el-icon-chat-dot-square"></i> 消息中心</el-dropdown-item>
                        <el-dropdown-item command="members"><i class="el-icon-trophy"></i> 会员信息</el-dropdown-item>
                        <!-- <el-dropdown-item command="star"><i class="el-icon-star-off"></i> 关注评论</el-dropdown-item> -->
                        <el-dropdown-item command="wechat">
                            <i class="el-icon-connection"></i>
                            {{ $store.state.user.openid ? '修改绑定' : '绑定微信' }}
                        </el-dropdown-item>
                        <!-- <el-dropdown-item command="make"><i class="el-icon-help"></i> 材料编辑</el-dropdown-item> -->
                        <el-dropdown-item command="login"><i class="el-icon-switch-button"></i> 退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="taoshu-body" v-if="userLoaded">

            <!--<div class="taoshu-body">-->
            <router-view></router-view>
            <!--</div>-->

            <!--显示HTML-->
            <ts-dialog :show.sync="$store.state.htmlView.show" title="详情" hide-footer>
                <div class="html-view" v-html="$store.state.htmlView.content"></div>
            </ts-dialog>

            <!--公共的confirm-->
            <ts-dialog :show.sync="$store.state.alertInfo.show" width="400px" :title="$store.state.alertInfo.title"
                :hideCancel="$store.state.alertInfo.hideCancel" @save="saveConfirm"
                :loading="$store.state.alertInfo.loading">
                <div class="flex">
                    <div class="my-dialog__msg" v-html="$store.state.alertInfo.content"></div>
                </div>
            </ts-dialog>

            <!--二维码弹窗-->
            <ts-dialog :show.sync="qrCode.show" title="绑定微信" hide-footer>
                <div v-loading="qrCode.loading" style="width: 300px;height:300px;display: block;margin: 0 auto">
                    <img :src="qrCode.url" alt="">
                </div>
            </ts-dialog>
        </div>

        <my-comment></my-comment>
        <my-note></my-note>

        <!-- 右边悬浮导航栏 -->
        <div class="sidebar" :style="{ 'right': expand ? '0' : '-45px' }">
            <el-menu class="el-menu-vertical-demo sidebar_right" :class="[!isCollapse ? 'sidebar_right_width' : '']"
                @select="handleOpen" :collapse="isCollapse" background-color="#F7F7F7">
                <el-menu-item v-for="(item, i) in rightList" :index="item.index" :key="i" v-show="item.isShow">

                    <div class="menu_item">
                        <el-badge :value="item.comList.length" :max="10" class="item" v-if="item.isbadge && item.isImg"
                            :hidden="item.comList.length === 0 ? true : false">
                            <el-image v-if="item.isImg" class="img" :src="item.url" fit="fill"></el-image>
                            <div class="menu_item_text">{{ item.name }}</div>
                        </el-badge>
                        <el-image v-else-if="!item.isbadge && item.isImg" class="img" :src="item.url"
                            fit="fill"></el-image>
                        <i v-else :class="item.icon"></i>
                        <div v-if="!item.isbadge" class="menu_item_text">{{ item.name }}</div>
                    </div>

                    <template slot="title">
                        <div v-if="item.type === 'popover'">
                            <div class="name">{{ item.name }}</div>
                            <div>
                                <el-image v-if="item.picUrl" class="big" :src="item.picUrl" fit="fill"></el-image>
                                <div v-if="item.desc" class="desc"> {{ item.desc }} </div>
                                <div v-show="item.list && item.list.length != 0" class="list" v-for="child in item.list"
                                    :key="child.mode" @click="onContact(child)">
                                    <div class="mode">{{ child.mode }}：</div>
                                    <div class="number">{{ child.number }}</div>
                                </div>
                                <div class="comText">
                                    <template v-if="item.comList && item.comList.length != 0">
                                        <div class="comText_height">
                                            <div class="comText_com" v-for="(orr, i) in item.comList" :key="orr.id">
                                                {{ orr.comName }}
                                                <i class="el-icon-delete" style="color: red;"
                                                    @click="$store.state.contrastList.splice(i, 1)"></i>
                                            </div>
                                        </div>
                                        <div class="comText_btn" @click="goContrast">跳转药品对比</div>
                                    </template>
                                    <div class="comText_com" v-if="item.comList && item.comList.length === 0"
                                        style="justify-content: center;">
                                        暂无数据！</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-menu-item>
            </el-menu>
            <div class="expand" @click="expand = !expand">
                工具栏
                <i :class="expand ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></i>
            </div>
        </div>

        <feed-back v-if="showBack && $help.token.get()" :showBack="showBack" />


        <div id="cs-btn" class="cs-btn" v-drag-div @click="goCustom">
            <div class="img-cs">
                <div class="clickable img-cs__panel">
                    <img src="@/assets/img/cs.gif" alt="客服">
                    <div class="tip-num" v-show="$store.state.unReadCount > 0">{{ $store.state.unReadCount }}</div>
                </div>
            </div>
        </div>


        <gov></gov>

        <ts-dialog :show.sync="$store.state.calcShow" title="计算器" close-on-click-modal hide-footer width="400px">
            <calculator></calculator>
        </ts-dialog>

        <customer-service ref="cs" />
        <ts-avatar ref="avatar"></ts-avatar>


        <!--药品对比弹窗-->
        <custom-dialog v-if="this.$store.state.contrastShow" @close="closeCustomDialog" />
        <!--成分、适应症组合对比弹窗-->
        <combination-dialog v-if="this.$store.state.combinationObj.show" :newTitle="this.$store.state.combinationObj.title"/>
    </el-container>
</template>

<script>
import MyComment from './../components/MyComment.vue'
import MyNote from './../components/MyNote'
import Calculator from './../components/Calculator.vue'
import CustomerService from './../components/CustomerService'
import CustomDialog from './../components/CustomDialog'
import CombinationDialog from './../components/CombinationDialog'
import FeedBack from "@/pages/FeedBack.vue";
import help from "@/util/help";
export default {
    components: { FeedBack, MyComment, Calculator, MyNote, CustomerService, CustomDialog,CombinationDialog},
    data() {
        return {
            loaded: false,
            userLoaded: false,
            active: '',
            menu: [],

            qrCode: {
                show: false,
                loading: false,
                url: '',
                key: '',
            },

            feedBack: require('@/assets/img/feed-back.png'),

            showBtn: false,
            canOpenBtn: true,
            showBack: {
                show: false,
            },
            showPopover: {
                show: false,
                obj: {},
            },

            expand: false,  //是否展开
            isCollapse: true,  //水平折叠收起菜单
            rightList: [
                {
                    index: '0', name: '信息反馈', isImg: true, type: 'feedback', isShow: this.$help.token.get(),
                    url: require('@/assets/img/feedback.png'),
                },
                {
                    index: '1', name: '公众号', isImg: true, desc: '扫码关注公众号', isShow: true,
                    type: 'popover', picUrl: require('@/assets/img/public.png'), width: 160,
                    url: require('@/assets/img/official-account.png'),
                },
                {
                    index: '2', name: '联系方式', isImg: true, type: 'popover', isShow: true,
                    list: [
                        { mode: '联系电话', number: '400-8676768', type: 'tel' },
                        { mode: '联系QQ', number: '2376201058', type: 'QQ' },
                    ],
                    width: 200, picUrl: require('@/assets/img/wechat1.png'),
                    desc: '扫码加我微信',
                    url: require('@/assets/img/contact-information.png'),

                },
                {
                    index: '3', name: '药品对比', isImg: true, isShow: this.$help.token.get(), isbadge: true,
                    comList: this.$store.state.contrastList,
                    type: 'popover', width: 160, url: require('@/assets/img/Drug-comparison.png'),
                },
            ],
        }
    },
    created() {
        this.getMenu()
        this.getUser()
        this.$store.dispatch('getUnread')
    },
    watch: {
        $route(to) {
            //判断当前为药品对比页面
            if (!this.$help.token.get() || to.path === '/drug-compare') {
                this.rightList[3].isShow = false
            } else {
                this.rightList[3].isShow = true
            }
        }
    },
    methods: {
        closeCustomDialog() {
            this.$store.commit('setContrastShow', false)
        },
        goContrast() {
            const { href } = this.$router.resolve({
                path: '/drug-compare',
            });
            localStorage.setItem("all_contrastList", JSON.stringify(this.$store.state.contrastList));
            window.open(href, '_blank');
        },
        handleOpen(key) {
            const row = this.rightList[key]
            if (row.type === 'feedback') {
                if (this.$help.token.get()) {
                    this.showBack.show = true

                } else {
                    this.$store.commit('alert', `联系电话：${this.$variable.tel}`)
                }
            }
        },
        showBtnFun() {
            if (!this.canOpenBtn) return
            this.showBtn = true
        },
        hideBtnFun() {
            this.canOpenBtn = false
            this.showBtn = false
            setTimeout(() => {
                this.canOpenBtn = true
            }, 1000)
        },
        goCustom() {
            if (window.removeFinished) {
                this.$refs.cs.showDialog()
            }
        },

        //联系
        onContact(data) {
            if (data.type === 'tel') {
                window.location.href = `tel://${data.number}`
            }
            if (data.type === 'QQ') {
                window.location.href = `tencent://message/?uin=${data.number}`
            }
        },

        handleCommand(command) {
            if (command === 'login') {
                this.$confirm('是否确认退出', '提示', {
                    confirmButtonText: '退出',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        this.$api.delete('sys/sysUser/logout')
                        this.$help.token.remove()
                        help.removeClientId()
                        this.$help.goLogin()
                    })
                    .catch(() => { })
            } else {
                let url = ''
                if (command === 'members') {
                    url = `/members`
                } else if (command === 'message') {
                    url = `/message`
                } else if (command === 'star') {
                    url = `/star`
                } else if (command === 'note') {
                    url = `/note`
                } else if (command === 'wechat') {
                    if (this.$store.state.user.openid) {
                        this.$store.commit('confirm', {
                            content: '你已绑定微信，确定修改绑定？',
                            callback: (next) => {
                                next()
                                this.loadImg()
                            }
                        })
                    } else {
                        this.loadImg()
                    }
                } else if (command === 'make') {
                    url = `/make`
                }
                if (url) {
                    const { href } = this.$router.resolve({
                        path: url,
                    })
                    window.open(href, "_blank")
                }
            }
        },
        loadImg() {
            this.qrCode.show = true
            this.qrCode.loading = true
            this.$api.get('sys/sysUser/queryBindWechatQrCode', { force: true })
                .then(res => {
                    this.qrCode.url = res.data.qrCode
                    this.qrCode.key = res.data.key
                    this.cycleApi()
                })
                .finally(() => {
                    this.qrCode.loading = false
                })
        },
        cycleApi() {
            if (this.qrCode.key && this.qrCode.show) {
                this.$api.get('sys/sysUser/queryBindWechatStatus', { randStr: this.qrCode.key })
                    .then(res => {
                        if (res.code === 'S0001') {
                            this.loadImg()
                            this.$store.commit('error', '二维码已失效，正在刷新二维码')
                        } else if (res.data.status === 'bind_fail') {
                            this.$store.commit('error', res.data.errorMsg)
                            this.loadImg()
                        } else if (res.data.status === 'bind_success') {
                            this.$store.commit('success', '绑定成功')
                            window.location.reload()
                        } else {
                            setTimeout(() => {
                                this.cycleApi()
                            }, 1000)
                        }
                    })
            }
        },


        getMenu() {
            this.$store.commit('loading')
            this.$api.get('setting/year/yearList')
                .then(res => {
                    this.$store.commit('setYears', res.data)
                })
                .finally(() => {
                    this.$api.get('sys/sysMenu/querySysMenuList')
                        .then(res => {
                            this.menu = res.data

                            const param = {}
                            res.data.forEach(parent => {
                                parent.children && parent.children.forEach(item => {
                                    param[item.menuCode] = item.menuName
                                })
                            })
                            param.rd_registration_acceptances = '药品注册与受理数据库'
                            this.$store.commit('setTitleRefer', param)
                        })
                        .finally(() => {
                            this.loaded = true
                            this.$store.commit('loaded')

                            this.$nextTick(() => {
                                // 保证客服机器人在右下角
                                const screenWidth = window.innerWidth
                                const screenHeight = window.innerHeight
                                const csBtn = document.getElementById('cs-btn')
                                csBtn.style.left = `${screenWidth - 100}px`
                                csBtn.style.top = `${screenHeight - 130}px`

                                if (!this.$help.store.get('sendingMethod')) {  //设定发送方式
                                    this.$help.store.set('sendingMethod', 'Enter')
                                }
                            })
                        })
                })
        },
        getPage(menu, parent) {
            if (menu.id === 'DM17_12') {
                // 药品关键字-剂型
                return '/through-dose'
            } else if (menu.id === 'DM15_09') {
                //药品成本测算
                return '/cost-estimation-drugs'
            } else if (menu.id === 'DM13_18') {
                // 招投标信息(全国)
                return '/bidding-info-country'
            } else if (menu.id === 'DM13_19') {
                // 招投标信息(各省)
                return '/bidding-info-province'
            } else if (menu.id === 'DM15_13') {
                // 中药材价格
                return '/ch-medicinal-price'
            } else if (menu.id === 'DM17_13') {
                // 药品通用名-成分
                return '/through-component'
            } else if (menu.id === 'DM17_14') {
                // 药品通用名-适应症
                return '/through-indication'
            } else if (menu.id === 'DM17_15') {
                // 药品通用名-类别
                return '/through-category'
            } else if (menu.id === 'DM17_19') {
                // 药品对比
                return '/drug-compare'
            } else if (parent.id === 'DM18') {
                return `/new/${menu.menuCode}`
            } else {
                return `/store/${menu.menuCode}`
            }
        },
        getUser() {
            if (this.$help.token.get()) {
                this.$store.dispatch('getUser').finally(() => {
                    this.userLoaded = true
                })
            } else {
                this.userLoaded = true
            }
        },

        // region 公共confirm相关方法
        saveConfirm() {
            this.$store.state.alertInfo.loading = true
            this.$store.state.alertInfo.callback(this.closeConfirm, () => {
                this.$store.state.alertInfo.loading = false
            })
        },
        closeConfirm() {
            this.$store.state.alertInfo.show = false
            this.$store.state.alertInfo.loading = false
        },
        // endregion
    },
}
</script>

<style lang="scss">
@import "~@/assets/css/var";

.corp-info {
    width: 100%;
    padding: 30px 200px;
    overflow-y: auto;

    .el-row {
        width: 100%;
    }

    &-item {
        border-bottom: 1px solid #EBEBEB;
        padding-top: 30px;

        .title {
            display: flex;
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
            color: #999;
            padding-bottom: 10px;

            .comment-star {
                flex: 1;
                font-size: 12px;
            }
        }

        .content {
            padding-bottom: 23px;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: #333;

            &.clickable {
                color: $main;
                cursor: pointer;

                &:hover {
                    opacity: .7;
                }
            }
        }
    }
}
</style>